<template>
  <div>
    <VLoading v-bind:loading="loading" />
    <div v-if="set">
      <b-row>
        <b-col>
          <b-card>
            <b-card-title>
              <b-row>
                <b-col
                  >Product Set
                  <VClipCopy
                    :from="'vproductm'"
                    v-bind:uuid="set.id"
                    v-bind:name="'product'"
                    v-bind:key="'product-' + set.id"
                  />
                </b-col>
                <b-col>
                  <span class="float-right">
                    <b-form-checkbox
                      v-model="set.completed"
                      name="check-button"
                      @change="completeSet()"
                      switch
                    >
                      {{ set.completed ? "Completed" : "Not completed" }}
                    </b-form-checkbox>

                    <b-button
                      class="m-1"
                      v-on:click="saveSet()"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Save set"
                    >
                      <i class="fal fa-save"></i>
                    </b-button>

                    <b-button
                      class="m-1"
                      v-on:click="loadProductSet()"
                      variant="outline-primary"
                      v-b-tooltip.hover
                      title="Refresh set"
                    >
                      <i class="fal fa-sync-alt"></i>
                    </b-button>

                    <b-button
                      class="m-1"
                      v-on:click="removeSet()"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      title="Remove set"
                    >
                      <i class="fal fa-trash-alt"></i>
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </b-card-title>

            <b-card-text class="mt-2">
              Name:
              <b-form-input class="mb-2" v-model="set.name"></b-form-input>
              Name:
              <b-form-input class="mb-2" v-model="set.year"></b-form-input>
              Product Collection:
              <b-form-select
                v-model="set.product_collection.id"
                :options="product_collections"
                :disabled="true"
                value-field="id"
                text-field="name"
                class="mb-2"
                @change="saveSet()"
              ></b-form-select>
              Created:
              <b-form-input
                class="mt-2"
                disabled
                v-model="set.created"
              ></b-form-input>
            </b-card-text>
          </b-card>

          <b-card class="mt-4">
            <b-card-text>
              <h4>
                Products ({{ count }})
              </h4>
              <div
                class="d-flex justify-content-center mt-3"
                v-if="loading_products"
              >
                <b-spinner label="Loading products..."></b-spinner>
                <span class="ml-2">Loading products...</span>
              </div>
              <div v-else>
                Add Product to set:
                <b-input-group class="mt-2">
                  <b-form-input
                    v-model="new_product_id"
                    placeholder="Enter product ID"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Add Product to Set"
                      @click="addProductToSet()"
                    >
                      <i class="fal fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-card-text>
            <b-table
              :items="products"
              :fields="product_fields"
              class="mt-3"
              @row-clicked="onRowClicked"
              :tbody-tr-class="rowClass"
              outlined
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(name)="data">
                {{ data.item.name }}
                <VClipCopy
                  :from="'vproducti'"
                  v-bind:uuid="data.item.id"
                  v-bind:name="'Image'"
                  v-bind:key="'producti-' + data.item.id"
                />
              </template>

              <template v-slot:cell(number)="data">
                {{ extractNumberFromCustomerId(data.item.customer_product_id) }}
              </template>

              <template v-slot:cell(thumb)="data">
                <VZoomImage
                  :id="data.item.id"
                  :image="data.item.thumb"
                  :thumb="data.item.thumb"
                  :name="'Image'"
                />
              </template>

              <template v-slot:cell(objects)="data">
                <b-badge
                  v-if="JSON.stringify(data.item._objects) != '{}'"
                  variant="primary"
                  class="m-1"
                >
                  Objects
                </b-badge>
              </template>

              <template v-slot:cell(verified)="data">
                <i
                  v-if="data.item.verified"
                  class="fal fa-check text-success"
                ></i>
                <i v-else class="fal fa-times text-danger"></i>
              </template>

              <template v-slot:cell(action)="data">
                <b-button
                  class="mr-1"
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Remove from Set"
                  @click="removeProductFromSet(data.item.id)"
                >
                  <i class="fal fa-trash-alt"></i>
                </b-button>

                <router-link
                  v-bind:to="/product/ + data.item.id"
                  target="_blank"
                >
                  <b-button
                    class="mr-1"
                    variant="outline-primary"
                    v-b-tooltip.hover
                    title="Open Product in New Tab"
                  >
                    <i class="fal fa-search"></i>
                  </b-button>
                </router-link>

                <b-button
                  class="mr-1"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="Select Product"
                  @click="selectProduct(data.item.id)"
                >
                  <i class="fal fa-mouse-pointer"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              class="mt-3"
              v-if="!loading"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              label-page
              first-number
              last-number
              size="sm"
              hide-goto-end-buttons
            />
          </b-card>
        </b-col>
        <b-col>
          <b-tabs content-class="mt-3">
            <b-tab title="Product Set Meta">
              <JSONEditor
                style="height: 200px;"
                :options="options"
                ref="editor"
                :onChange="onSetMetaChange"
                :json="set.meta_data"
              />
            </b-tab>
          </b-tabs>

          <b-card class="mt-4" v-if="product">
            <b-card-text>
              <h4>
                Product details
              </h4>
              <h6>Name: {{ product.name }}</h6>
              <h6>Customer Product ID: {{ product.customer_product_id }}</h6>
            </b-card-text>
            <JSONEditor
              style="height: 200px;"
              :options="options"
              :ref="'productEditor'"
              :onChange="onProductChange"
              :json="product ? product.meta_data : {}"
              id="product-meta-editor"
            />

            <b-table
              :items="images"
              :fields="image_fields"
              class="mt-3"
              outlined
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(name)="data">
                {{ data.item.name }}
                <VClipCopy
                  :from="'vproducti'"
                  v-bind:uuid="data.item.id"
                  v-bind:name="'Image'"
                  v-bind:key="'producti-' + data.item.id"
                />
              </template>

              <template v-slot:cell(thumb)="data">
                <VZoomImage
                  :id="data.item.id"
                  :image="data.item.thumb"
                  :thumb="data.item.thumb"
                  :name="'Image'"
                />
              </template>
            </b-table>
          </b-card>
          <b-pagination
            class="mt-3"
            v-if="!loading"
            v-model="currentPricePage"
            :total-rows="totalPriceRows"
            :per-page="perPricePage"
            label-page
            first-number
            last-number
            size="sm"
            hide-goto-end-buttons
          />
        </b-col>
      </b-row>
    </div>
    <div v-if="error">
      <b-container class="bv-example-row mt-3">
        <b-row>
          <b-col>
            <b-card class="container">
              <b-card-body>
                <b>Product Set not found!</b>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Confirmation Modal -->
    <b-modal
      id="deleteSetModal"
      title="Delete Set"
      @ok="confirmDeleteSet"
      ok-variant="danger"
      ok-title="Delete"
      cancel-title="Cancel"
    >
      <p>
        Are you sure you want to delete this set? This action cannot be undone.
      </p>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VLoading from "@/components/VLoading.vue";
import JSONEditor from "vue2-jsoneditor";
import VUpload from "@/components/products/VUpload.vue";
import VClipCopy from "../utils/VClipCopy.vue";
import VZoomImage from "@/components/utils/VZoomImage.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VProduct",
  components: {
    JSONEditor,
    VLoading,
    VClipCopy,
    VZoomImage
  },
  data() {
    return {
      count: 0,
      set: null,
      product: null,
      images: [],
      products: [],
      prices: [],
      loading: true,
      loading_products: false,
      perPricePage: 5,
      currentPricePage: 1,
      totalPriceRows: 0,
      product_fields: ["name", "number", "thumb", "verified", "action"],
      image_fields: ["name", "thumb"],
      error: false,
      move_items: 0,
      msg_remove: false,
      selected_image: null,
      verified: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      new_product_id: "",
      options: {
        mode: "tree",
        modes: ["text", "tree", "view"],
        search: true,
        colorPicker: true,
        history: true
      }
    };
  },
  props: {
    set_id: String
  },
  computed: {
    ...mapState(["data_loaded", "product_collections", "user_id", "workspace"])
  },
  methods: {
    extractNumberFromCustomerId(customerId) {
      if (!customerId) return "";
      // Find all numbers in the string
      const matches = customerId.match(/\d+/g);
      // Return the last number found, or empty string if none found
      return matches ? matches[matches.length - 1] : "";
    },
    getCollectionName(type) {
      let item = this.product_collections.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item;
      } else {
        return "Unknown";
      }
    },
    onRowClicked(item, index, event) {
      this.selectProduct(item.id);
      this.selected_image = item;
    },
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async saveSet() {
      let response = await axios
        .put(
          API_URL +
            "/product/v2/set/" +
            this.set_id +
            "/?workspace=" +
            this.workspace,
          {
            year: this.set.year,
            name: this.set.name,
            product_collection: this.set.product_collection.id
          }
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
    },
    async loadProductSet() {
      console.log("loadProduct...");
      let response = await axios
        .get(
          API_URL +
            "/product/v2/set/" +
            this.set_id +
            "/?workspace=" +
            this.workspace
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      // switch workspace
      if (this.workspace != response.data["workspace"]) {
        await this.$store.dispatch("changeWorkspace", {
          workspace: response.data["workspace"]
        });
      }

      this.loadProducts();

      if (response) {
        this.set = response.data;
        this.loading = false;
      } else {
        this.set = null;
        this.error = true;
        this.loading = false;
      }
    },
    fillSelected(product, field) {
      let items = product[field];
      for (let index = 0; index < items.length; index++) {
        product[field][index]["selected"] = false;
      }
    },
    async loadProducts() {
      this.products = [];
      this.loading_products = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            API_URL +
              "/product/v2/product/?workspace=" +
              this.workspace +
              "&product_set=" +
              this.set_id +
              "&page_size=" +
              this.perPage +
              "&page=" +
              this.currentPage
          )
          .then(
            response => {
              this.count = response.data["count"];
              this.products = response.data["results"];
              this.totalRows = response.data["count"];
              this.loading_products = false;
              resolve(response);
            },
            error => {
              this.loading_products = false;
              reject(error);
            }
          );
      });
    },
    async selectProduct(product_id) {
      this.images = [];

      axios
        .get(
          API_URL +
            "/product/v2/product/" +
            product_id +
            "/?workspace=" +
            this.workspace
        )
        .then(response => {
          this.product = response.data;
        });

      axios
        .get(
          API_URL +
            "/product/v2/image/?workspace=" +
            this.workspace +
            "&product=" +
            product_id
        )
        .then(
          response => {
            this.images = response.data["results"];
          },
          error => {}
        );
    },
    onProductChange(newJson) {
      axios
        .patch(
          API_URL +
            "/product/v2/product/" +
            this.product.id +
            "/?workspace=" +
            this.workspace,
          {
            meta_data: newJson
          }
        )
        .then(function(response) {}, function(error) {});
    },
    async completeSet() {
      if (this.set.completed) {
        let response = await axios
          .post(API_URL + "/product/v2/set/" + this.set.id + "/complete", {
            set: this.set.id
          })
          .catch(error => {
            this.loading = false;
            this.error = true;
          });
      } else {
        let response = await axios
          .post(API_URL + "/product/v2/set/" + this.set.id + "/uncomplete", {
            set: this.set.id
          })
          .catch(error => {
            this.loading = false;
            this.error = true;
          });
      }
    },

    onSetChange() {
      console.log("Set", this.set);
      axios
        .patch(
          API_URL +
            "/product/v2/set/" +
            this.set_id +
            "/?workspace=" +
            this.workspace,
          {
            completed: this.set.completed,
            name: this.set.name
          }
        )
        .then(function(response) {}, function(error) {});
    },

    onSetMetaChange(newMeta) {
      if (newMeta) {
        try {
          if (typeof newMeta === "string") {
            JSON.parse(newMeta);
          } else {
            JSON.stringify(newMeta);
          }
          this.set.meta_data = newMeta;
        } catch (e) {
          return;
        }
      }

      axios
        .patch(
          API_URL +
            "/product/v2/set/" +
            this.set_id +
            "/?workspace=" +
            this.workspace,
          {
            meta_data: this.set.meta_data
          }
        )
        .then(function(response) {}, function(error) {});
    },
    async removeSet() {
      this.$bvModal.show("deleteSetModal");
    },
    async confirmDeleteSet() {
      try {
        await axios.delete(
          API_URL +
            "/product/v2/set/" +
            this.set_id +
            "/?workspace=" +
            this.workspace
        );
        // Redirect to sets list after successful deletion
        this.$router.push("/sets");
      } catch (error) {
        console.error("Error removing set:", error);
        // Show error toast
        this.$bvToast.toast("Failed to delete set", {
          title: "Error",
          variant: "danger",
          solid: true
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.selected_image && this.selected_image.id == item.id)
        return "table-success";
    },
    async addProductToSet() {
      if (!this.new_product_id) {
        this.$bvToast.toast("Please enter a product ID", {
          title: "Error",
          variant: "danger",
          solid: true
        });
        return;
      }

      try {
        await axios.put(
          API_URL +
            "/product/v2/product/" +
            this.new_product_id +
            "/?workspace=" +
            this.workspace,
          {
            product_set: this.set.id,
            product_collection: this.set.product_collection.id
          }
        );

        // Clear the input and refresh the products list
        this.new_product_id = "";
        await this.loadProducts();

        this.$bvToast.toast("Product added to set successfully", {
          title: "Success",
          variant: "success",
          solid: true
        });
      } catch (error) {
        console.error("Error adding product to set:", error);
        let errorMessage = "Failed to add product to set";
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          errorMessage += ": " + error.response.data.detail;
        } else if (error.message) {
          errorMessage += ": " + error.message;
        }
        this.$bvToast.toast(errorMessage, {
          title: "Error",
          variant: "danger",
          solid: true
        });
      }
    },

    async removeProductFromSet(productId) {
      try {
        await axios.put(
          API_URL +
            "/product/v2/product/" +
            productId +
            "/?workspace=" +
            this.workspace,
          {
            product_set: null,
            product_collection: this.set.product_collection.id
          }
        );

        // Refresh the products list
        await this.loadProducts();

        this.$bvToast.toast("Product removed from set successfully", {
          title: "Success",
          variant: "success",
          solid: true
        });
      } catch (error) {
        console.error("Error removing product from set:", error);
        let errorMessage = "Failed to remove product from set";
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          errorMessage += ": " + error.response.data.detail;
        } else if (error.message) {
          errorMessage += ": " + error.message;
        }
        this.$bvToast.toast(errorMessage, {
          title: "Error",
          variant: "danger",
          solid: true
        });
      }
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadProductSet();
      }
    },
    currentPage() {
      this.loadProducts();
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadProductSet();
    }
  },
  created() {
    this.$eventBus.$on(
      "product-id-refresh-" + this.set_id,
      this.loadProductSet
    );
  },
  beforeDestroy() {
    this.$eventBus.$off("product-id-refresh-" + this.set_id);
  }
};
</script>

<style></style>
